<template>
    <div class="flex h-screen">
        <UButton 
            class="fixed top-8 right-8"
            @click="goHome"
            tttrailing-icon="!isAuth ? 'i-mdi-form-textbox-password' : 'i-mdi-home'"
            variant="soft"
            llabel="!isAuth ? 'Login' : 'Home'"
            label="Login"
        />

        <div v-if="route.params.catchAll[0] === '401'" class="m-auto text-center">
            <h1 class="mb-4 text-4xl text-red-500 tracking-widest uppercase font-black animate-bounce drop-shadow-2xl">
                Error 401
            </h1>
            <p class="mb-2">
                You are not authorized<br>
                to access this page!
            </p>
        </div>

        <div v-else vvv="route.params.catchAll[0] === '404'" class="m-auto text-center">
            <h1 class="mb-4 text-4xl text-red-500 tracking-widest uppercase font-black animate-bounce drop-shadow-2xl">
                Error 404
            </h1>
            <p class="mb-2">
                Sorry. Shit happens, but<br>
                this page doesn't exist!
            </p>
        </div>
    </div>
</template>
<script setup>

const route = useRoute()
const router = useRouter()
const auth = useAuth()
const isAuth = computed(() => auth?.eijnsJWT ? true : false)

definePageMeta({
    layout: "userless",
});

const goHome = () => {
    window.location = '/logout'
    // window.location = isAuth.value ? '/home' : '/logout'
}

</script>
<style lang="scss">
/* as */
</style>